import axios from 'axios';
// import { BASE_URL, TIMEOUT } from '../config';

const instance = axios.create({
    // baseURL: BASE_URL,
    // timeout: TIMEOUT
});

// 添加拦截
instance.interceptors.request.use(config => {
    // console.log("请求被拦截", config);
    return config;
}, error => {
    console.error('axios', error);
});

instance.interceptors.response.use(res => {
    return res.data;
}, error => {
    console.error('axios', error);
});

export default instance;