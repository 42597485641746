import { useState, useEffect } from 'react';
import weixin from 'weixin-js-sdk';
import './index.scss';
import { mxd_info, mxd_package, mxd_ticket, mxd_pay_config } from '../../api';
// nutui
import { Button, Input, Divider, Picker, Toast, Popup } from '@nutui/nutui-react';

export default function Recharge() {
    const wxOpenLaunchWeappStype = {
        backgroundColor: 'rgb(172, 129, 28)',
        color: '#ffffff',
        fontSize: '18px',
        width: '300px',
        margin: '20px 0 0 0',
        padding: '12px',
        borderRadius: '10px',
        border: 'none'
    }
    const [wxOpenLaunchWeappPath, setWxOpenLaunchWeappPath] = useState('');
    const buttonStyle = { width: "calc((100vw - 3rem) / 2)", borderRadius: '.2rem' };
    const [account, setAccount] = useState('');
    const [roleData, setRoleData] = useState([]);
    const [role, setRole] = useState(null);
    const [rolePicker, setRolePicker] = useState(false);
    const game_currency = [
        { text: '10元', value: 10 },
        { text: '50元', value: 50 },
        { text: '100元', value: 100 },
        { text: '200元', value: 200 },
        { text: '300元', value: 300 },
        { text: '500元', value: 500 },
        { text: '1000元', value: 1000 },
    ];
    const [active, setActive] = useState(10);
    const [money, setMoney] = useState();
    const [num, setNum] = useState(0);
    const [confirmPayPopup, setConfirmPayPopup] = useState(0);
    const [rechargeAmount, setRechargeAmount] = useState(0);
    const [payConfig, setPayConfig] = useState([]);

    useEffect(() => {
        mxd_ticket({ url: window.location.href }).then(res => {
            const { appId, nonceStr, signature, timestamp } = res;
            weixin.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
                appId, // 必填，公众号的唯一标识
                timestamp, // 必填，生成签名的时间戳
                nonceStr, // 必填，生成签名的随机串
                signature,// 必填，签名
                jsApiList: ['wx-open-launch-weapp',], // 必填，需要使用的JS接口列表
                openTagList: ['wx-open-launch-weapp'], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
            });
        }).catch(err => {
            console.log(err);
        });

        mxd_pay_config().then(res => {
            setPayConfig(res);
        });
    }, []);// 空数组意味着仅在组件挂载时执行一次

    function getRoleData() {
        if (account) {
            mxd_info(account).then(res => {
                const roleData = [];
                Object.keys(res).forEach(key => {
                    roleData.push({ value: key, text: res[key] });
                });
                setRoleData(roleData);
                setRolePicker(true);
            });
        } else {
            Toast.show({ content: '请填写账号' });
        }
    }

    function confirmRole(list, value) {
        setRole(list[0]);
        mxd_package(account, list[0].value).then(res => {
            setNum(res.num);
        });
    }

    function confirm() {
        const recharge_amount = Number(money || active);
        if (!account) {
            Toast.show('请填写账号');
        } else if (!recharge_amount) {
            Toast.show('请选择充值金额');
        } else if (!role) {
            Toast.show('请选择角色');
        } else if (num * 9999 < recharge_amount * 10) {
            Toast.show(`背包容量不足（${num * 9999}）`);
        } else {
            setWxOpenLaunchWeappPath(`/otherHelp/mapleStoryPay/mapleStoryPay?money=${recharge_amount}&account=${account}&name=${role.text}&characters_id=${role.value}`);
            setRechargeAmount(recharge_amount);
            setConfirmPayPopup(true);
        }
    }

    return (
        <div className="recharge">
            {/* 用户信息 */}
            <div className="info">
                <div className="item">
                    <div>游戏账号</div>
                    <div className='value'>
                        <Input value={account} type="digit" align="right" placeholder="游戏账号" onChange={(val) => setAccount(val)} />
                    </div>
                </div>
                <Divider />
                <div className="item">
                    <div>选择角色</div>
                    <span className="value" onClick={() => getRoleData()}>{role ? role.text : '选择角色'}</span>
                </div>
            </div>

            {/* 充值信息 */}
            <div className="recharge-box">
                <div className="title">
                    <span>充值金额</span>
                    <span className="tips">（10元 = 100购物券 + 1000点券）</span>
                </div>
                <div className="content">
                    {game_currency.map(item =>
                        <div key={`1-${item.value}`} className={`item ${(active === item.value && !money) ? 'active' : ''}`} onClick={() => setActive(item.value)}>{item.text}</div>
                    )}
                    <div className='item'>
                        <Input value={money} type="number" align="center" placeholder="其它" onChange={(val) => setMoney(val)} />
                    </div>
                </div>
            </div>

            {/* 操作 */}
            <div className="operating-box">
                <Button color="rgb(172, 129, 28)" size="xlarge" style={{ ...buttonStyle, marginRight: '1rem' }} onClick={confirm}>确认</Button>
                <Button color="#5a5a5a" size="xlarge" style={buttonStyle}>取消</Button>
            </div>

            {/* 警告 */}
            <div className="warn">* 充值时请先下线 *</div>

            {/* 充值福利 */}
            {payConfig.map(item => <div className="warn">{item.name}</div>)}

            {/* 角色选择弹出框 */}
            <Picker title="请选择充值类型" visible={rolePicker} options={roleData} onConfirm={(list, values) => confirmRole(list, values)} onClose={() => setRolePicker(false)} />

            {/* 支付确认框 */}
            <Popup round zIndex={2000} visible={confirmPayPopup} onClose={() => { setConfirmPayPopup(false) }}>
                <div className="confirm-pay-popup">
                    <div className="item">
                        <span>账号：</span>
                        <span>{account}</span>
                    </div>
                    <div className="item">
                        <span>角色：</span>
                        <span>{role && role.text}</span>
                    </div>
                    <div className="item">
                        <span>点券：</span>
                        <span>{rechargeAmount * 10}</span>
                    </div>
                    <div className="item">
                        <span>购物券：</span>
                        <span>{rechargeAmount * 100}</span>
                    </div>
                    <div className="item">
                        <span>充值：</span>
                        <span>{rechargeAmount}元</span>
                    </div>
                    <wx-open-launch-weapp appid="wxa1bad677c02c190e" username="gh_1a339504387f" path={wxOpenLaunchWeappPath}>
                        <script type="text/wxtag-template">
                            <button style={wxOpenLaunchWeappStype}>立即支付</button>
                        </script>
                    </wx-open-launch-weapp>
                </div>
            </Popup>
        </div >
    )
}