import { useState, useEffect } from 'react';
import './index.scss';
import { mxd_info, mxd_package, mxd_pay, mxd_pay_config } from '../../api';
// nutui
import { Button, Input, Divider, Picker, Toast } from '@nutui/nutui-react';

export default function Recharge() {
    const buttonStyle = { width: "calc((100vw - 3rem) / 2)", borderRadius: '.2rem' };
    const [account, setAccount] = useState('');
    const [roleData, setRoleData] = useState([]);
    const [role, setRole] = useState(null);
    const [rolePicker, setRolePicker] = useState(false);
    const game_currency = [
        { text: '10元', value: 10 },
        { text: '50元', value: 50 },
        { text: '100元', value: 100 },
        { text: '200元', value: 200 },
        { text: '300元', value: 300 },
        { text: '500元', value: 500 },
        { text: '1000元', value: 1000 },
    ];
    const [active, setActive] = useState(10);
    const [money, setMoney] = useState();
    const [num, setNum] = useState(0);
    const [payConfig, setPayConfig] = useState([]);

    useEffect(() => {
        mxd_pay_config().then(res => {
            setPayConfig(res);
        });
    }, []);// 空数组意味着仅在组件挂载时执行一次

    function getRoleData() {
        if (account) {
            mxd_info(account).then(res => {
                const roleData = [];
                Object.keys(res).forEach(key => {
                    roleData.push({ value: key, text: res[key] });
                });
                setRoleData(roleData);
                setRolePicker(true);
            });
        } else {
            Toast.show({ content: '请填写账号' });
        }
    }

    function confirmRole(list) {
        setRole(list[0]);
        mxd_package(account, list[0].value).then(res => {
            setNum(res.num);
        });
    }

    function confirm() {
        const recharge_amount = Number(money || active);
        if (!account) {
            Toast.show('请填写账号');
        } else if (!recharge_amount) {
            Toast.show('请选择充值金额');
        } else if (!role) {
            Toast.show('请选择角色');
        } else if (num * 9999 < recharge_amount * 10) {
            Toast.show(`背包容量不足（${num * 9999}）`);
        } else {
            mxd_pay({ money: recharge_amount, account, name: role.text, characters_id: role.value }).then(res => {
                window.location.href = res;
            });
        }
    }

    return (
        <div className="recharge">
            {/* 用户信息 */}
            <div className="info">
                <div className="item">
                    <div>游戏账号</div>
                    <div className='value'>
                        <Input value={account} type="digit" align="right" placeholder="游戏账号" onChange={(val) => setAccount(val)} />
                    </div>
                </div>
                <Divider />
                <div className="item">
                    <div>选择角色</div>
                    <span className="value" onClick={() => getRoleData()}>{role ? role.text : '选择角色'}</span>
                </div>
            </div>

            {/* 充值信息 */}
            <div className="recharge-box">
                <div className="title">
                    <span>充值金额</span>
                    <span className="tips">（10元 = 100购物券 + 1000点券）</span>
                </div>
                <div className="content">
                    {game_currency.map(item =>
                        <div key={`1-${item.value}`} className={`item ${(active === item.value && !money) ? 'active' : ''}`} onClick={() => setActive(item.value)}>{item.text}</div>
                    )}
                    <div className='item'>
                        <Input value={money} type="number" align="center" placeholder="其它" onChange={(val) => setMoney(val)} />
                    </div>
                </div>
            </div>

            {/* 操作 */}
            <div className="operating-box">
                <Button color="rgb(172, 129, 28)" size="xlarge" style={{ ...buttonStyle, marginRight: '1rem' }} onClick={confirm}>确认</Button>
                <Button color="#5a5a5a" size="xlarge" style={buttonStyle}>取消</Button>
            </div>

            {/* 警告 */}
            <div className="warn">* 充值时请先下线 *</div>

            {/* 充值福利 */}
            {payConfig.map(item => <div className="warn">{item.name}</div>)}

            {/* 角色选择弹出框 */}
            <Picker title="请选择充值类型" visible={rolePicker} options={roleData} onConfirm={(list, values) => confirmRole(list, values)} onClose={() => setRolePicker(false)} />
        </div >
    )
}