// 样式
import './App.scss';
import '@nutui/nutui-react/dist/style.css';
//
import routerMap from './router/map';
// 
import { Routes, Route } from 'react-router-dom';
import EnvironmentCheck from './components/EnvironmentCheck';

function App() {
  return (
    <div className="App">
      <Routes>
        {routerMap.map(item => <Route key={item.path} path={item.path} element={<EnvironmentCheck routerData={item} />} />)}
      </Routes>
    </div>
  );
}

export default App;
