import { Navigate } from 'react-router-dom';

export default function EnvironmentCheck(props) {
    const { routerData: { onlyWechat, component } } = props;

    const ua = navigator.userAgent.toLowerCase();
    const isWecht = (`${ua.match(/MicroMessenger/i)}` === "micromessenger");

    const pass = (onlyWechat && isWecht) || !onlyWechat;

    return (pass ? component : <Navigate to="/message" replace />)
}