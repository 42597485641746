import instance from './instance';
import { decrypt } from '../utils/CryptoJS';
import { BASE_URL } from '../config';

export const request = (url, method, data) => {
    return new Promise((resolve, reject) => {
        if (url.indexOf('https://') < 0)
            url = BASE_URL + url;
        const requestData = { url, method };
        if (method.toUpperCase() === 'POST')
            requestData.data = data;
        else if (method.toUpperCase() === 'GET')
            requestData.params = data;

        instance(requestData).then(res => {
            if (res) {
                // 解密
                if (typeof res === 'string')
                    res = JSON.parse(decrypt(res));

                if (res.code === 1) {
                    resolve(res.data);
                } else {
                    reject(res);
                }
            } else {
                reject('无返回值');
            }
        }).catch(err => {
            console.error(err);
            reject(err);
        });
    });
}

export const get = (url, data) => request(url, 'get', data);
export const post = (url, data) => request(url, 'post', data);