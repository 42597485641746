import { get } from './request';

/**
 * 获取角色列表
 * @param {string} account 账号
 * @returns Array
 */
export const mxd_info = (account) => get('api/mxd_info', { account });

/**
 * 剩余背包格子
 * @param {string} account 账号
 * @param {string} name 角色名
 * @returns number
 */
export const mxd_package = (account, role_id) => get('api/mxd_package', { account, role_id });

/**
 * 微信SDK注册参数
 * @returns object
 */
export const mxd_ticket = (data) => get('api/mxd_ticket', data);

/**
 * 支付信息
 * @returns object
 */
export const mxd_pay_config = () => get('https://hlb001.dashenliao.com/user/public/index.php/api/mxd/mxd_pay_config', {});

/**
 * 支付宝支付
 * @returns object
 */
export const mxd_pay = (data) => get('https://hlb001.dashenliao.com/user/public/index.php/api/mxd/mxd_cz', data);