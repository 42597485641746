import Message from '../pages/Message';
import Recharge from '../pages/Recharge';
import RechargeAlipay from '../pages/Recharge_alipay';

const routerMap = [
    { path: '/', component: <Recharge />, onlyWechat: true },
    { path: '/alipay', component: <RechargeAlipay />, onlyWechat: false },
    { path: '/message', component: <Message />, onlyWechat: false },
];

export default routerMap;